import { render, staticRenderFns } from "./Show.vue?vue&type=template&id=084b2db2&"
import script from "./Show.vue?vue&type=script&lang=ts&"
export * from "./Show.vue?vue&type=script&lang=ts&"
import style0 from "./Show.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowsStatusBanner: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/shows/StatusBanner.vue').default,AdaptiveImageNew: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/media/AdaptiveImageNew.vue').default,ButtonCircle: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/button/Circle.vue').default})
